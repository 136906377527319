import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Monorepos & packages seem to be all the rage. However, simply relocating code to
a package doesn’t make it more valuable. In fact, it can make it more expensive
& introduce unexpected risks! The real value comes from `}<strong parentName="p">{`good`}</strong>{` abstractions.
Packages are a set of tools to author, encapsulate & distribute such
abstractions. Here are some thoughts for you to consider when designing an
abstraction.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Fundamentally, computer science is a science of abstraction — creating the
right model for thinking about a problem and devising the appropriate
mechanizable techniques to solve it. -
`}<a parentName="p" {...{
          "href": "http://infolab.stanford.edu/~ullman/focs/ch01.pdf"
        }}>{`Jeffry Ullman, Computer Science: The Mechanization of Abstraction`}</a></p>
    </blockquote>
    <p>{`When creating a package it is important to
`}<a parentName="p" {...{
        "href": "https://www.facebook.com/notes/kent-beck/one-bite-at-a-time-partitioning-complexity/1716882961677894/"
      }}>{`consider the complexity we want the package to encapsulate`}</a>{`.
One of my favorite mental tools for reasoning about abstraction is the
abstraction graph introduced by Cheng Lou in his talk
`}<a parentName="p" {...{
        "href": "https://youtu.be/mVVNJKv9esE?t=304"
      }}>{`"The Spectrum of Abstraction”`}</a>{`, here is the
TLDR;`}</p>
    <p><a parentName="p" {...{
        "href": "/assets/images/articles/abstraction/spectrum-of-abstraction.png"
      }}><img parentName="a" {...{
          "src": "/assets/images/articles/abstraction/spectrum-of-abstraction.png",
          "alt": "A React Motion Abstraction Graph"
        }}></img></a></p>
    <p>{`A few definitions:`}</p>
    <ol>
      <li parentName="ol">{`Usefulness - Concrete use case for an abstraction.`}</li>
      <li parentName="ol">{`Power - How many downstream use cases an abstraction powers.`}</li>
      <li parentName="ol">{`Indirection Cost - Cognitive load is increased if an abstraction fails to
encapsulate its complexity. For example, if `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`react-motion`}</code>{` required us to “go
up” the chain and implement something with React directly in order to be
“useful”. Then we paid the cognitive (and code size) cost of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`react-motion`}</code>{` &
the cost of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`react`}</code>{` in order to fulfill our use case.`}</li>
    </ol>
    <p>{`The value of an abstraction should exceed the cost of indirection. This value
comes in the form of abstracting complexity and/or isolating risk.`}</p>
    <p>{`The interface for an abstraction should strive for the
`}<a parentName="p" {...{
        "href": "https://2014.jsconf.eu/speakers/sebastian-markbage-minimal-api-surface-area-learning-patterns-instead-of-frameworks.html"
      }}>{`minimum API surface`}</a>{`
intrinsically required to make it useful.`}</p>
    <p>{`An abstraction should not
`}<a parentName="p" {...{
        "href": "https://www.joelonsoftware.com/2002/11/11/the-law-of-leaky-abstractions/"
      }}>{`“leak”`}</a>{`
the complexity of whatever it is responsible for encapsulating. Else we are
doomed to incur the overhead of the abstraction and inherit the cost of the
thing we were trying to abstract in the first place.`}</p>
    <p><em parentName="p">{`Abstraction is a tool for collective thought`}</em>{`. It results in more cohesive APIs
& tooling across projects because people are thinking & collaborating about
things with a
`}<a parentName="p" {...{
        "href": "http://www.cs.virginia.edu/~evans/cs655/readings/steele.pdf"
      }}>{`similar language`}</a>{`.`}</p>
    <p>{`Abstraction is a double edged sword. The right abstraction can be immensely
valuable. The wrong abstraction can be extremely expensive. One is unlikely to
create a useful abstraction over something they don’t understand and given the
high impact potential of abstraction we should be prudent in our wielding of
this power. A great deal of the risk in an abstraction comes from its conceptual
overhead. Jeffry Ullman`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Don’t repeat yourself is the introductory step to the real principle; don’t
repeat concepts.” - Jimmy Koppel`}</p>
    </blockquote>
    <p>{`I’d recommend
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=f84n5oFoZBc"
      }}>{`Hammock Driven Development`}</a>{` as
part of your exercise in identifying abstractions.`}</p>
    <p>{`As a concrete example of a wonderful abstraction that abides by the above
principles I offer React. React abstracts the complexity of keeping the DOM up
to date efficiently & securely. React’s value also shines in its
`}<a parentName="p" {...{
        "href": "https://2014.jsconf.eu/speakers/sebastian-markbage-minimal-api-surface-area-learning-patterns-instead-of-frameworks.html"
      }}>{`drastic reduction of API surface area`}</a>{`
compared to working with the DOM directly, you no longer have to think about the
intricacies of the browser! Because you’ve taken on the constraints of working
with the abstraction new opportunities are afforded, such as rendering to a
different platform. React additionally gives us new concrete language like
“Component” & “Element” to collaborate, as well as the ability to define our own
language in terms of this collective language. React is truly a delightful
abstraction for this and many other reasons.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      